/*
  * Estilos SASS de la aplicación
  */
  
.ant-table-expanded-row > td:last-child {
  padding: 0 48px 0 8px;
}

.ant-table-expanded-row > td:last-child .ant-table-thead th {
  border-bottom: 1px solid #e9e9e9;
}

.ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
  padding-left: 0;
}

.ant-table-expanded-row > td:last-child .ant-table-row td:first-child {
  padding-left: 0;
}

.ant-table-expanded-row .ant-table-row:last-child td {
  border: none;
}

.ant-table-expanded-row .ant-table-thead > tr > th {
  background: none;
}

.table-operation a:not(:last-child) {
  margin-right: 24px;
}

.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}

.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}