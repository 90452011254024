
/*
  * Estilos SASS de la aplicación
  */
  
@import './mixins';

@keyframes background {
  
  from {
    background-color: #ffffff;
  }

  to {
    background-color: #edf9ff;
  }

}

.loading {

  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: #edf9ff;

  animation-name: background;
  animation-duration: 4s;

  @include display();
  @include align( center );
  
}