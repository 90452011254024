
/*
  * Estilos SASS de la aplicación
  */
  
.logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.layout-app {
  height: 100vh;
}