/*
  * Estilos SASS de la aplicación
  */
  
.search-form {
  
  padding: 24px;
  background: rgba(250, 250, 250, .1);;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

}

.search-result-list {

  margin-top: 16px;
  padding: 30px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: rgba(250, 250, 250, .1);

}

.custom-filter-dropdown {

  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);

  & input {
    width: 130px;
    margin-right: 8px;
  }

  & button {
    margin-right: 8px;
  }

}

.highlight {
  color: #f50;
}