/*
  * Estilos SASS de la aplicación
  */
  
.amarillo a :not( .ant-tag.ant-tag-yellow ) {
  background-color: #FFFAFA;
  padding: 3px;
  border-radius: 25%;
  border-style: solid;
  border-width: 1px;
  border-color: yellow;
}