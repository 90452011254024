/*
  * Estilos SASS de la aplicación
  */

@mixin align( $value ) {
  -webkit-box-align: $value;
  -webkit-align-items: $value;
  -ms-flex-align: $value;
  align-items: $value;
}

@mixin display() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin transition( $value, $duration ) {
  -webkit-transition: $value $duration;
  transition: $value $duration;
}