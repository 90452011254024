
/*
  * Estilos SASS de la aplicación
  */

@import './mixins';

.cuerpo{
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #900000, $alpha: 1.0);
  padding: 10px;
}
.imagenInicio{
  width: 70%;
  margin: auto;
  background-color: rgba($color: #ffffff, $alpha: 1.0);
  justify-content: center;
  @include display();
  @include align( center );}

  .imagenInicio img{
    width: 100%;
  }
.login {

  width: 70vw;
  height: 50vh;
  margin: auto;
  justify-content: center;
  background-color: rgba($color: #fafafa, $alpha: 1.0);
  
  @include display();
  @include align( center );
  
  .login-form {
    max-width: 300px;
  }
  .login a{
    margin: 0%;
    padding-block-start: 0%;
  }
  .login-form-forgot {
    float: right;
  }
  
  .login-form-button {
    width: 100%;
  }

}
.pie{
  width: 70vw;
  height: 10vh;
  margin: auto;
  justify-content: center;
  background-color: rgba($color: #fafafa, $alpha: 1.0);
  font-size: 200%;
  text-align: center;
  text-decoration: underline;
}